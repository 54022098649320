<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <DataTableMain
          :datatable="table"
          :on-success="successAction"
          :items-action="tableAction"
        />
      </v-col>
      <v-col cols="12">
        <ViewDetailsMain class="my-2" :object="objectDetail" type="CuentasxcobrarDetails" :items-action="tableAction" />
      </v-col>
    </v-row>
    <MainFormDialog v-if="get_dialogMain.dialog" :model="object" :params="paramsDialog"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DataTableMain from '@/components/tables/DataTableMain'
import MainFormDialog from '@/components/dialogs/MainFormDialog'
import ViewDetailsMain from '@/components/details/ViewDetailsMain.view'
import { Permission } from '@/mixins/permissionMain'
export default {
  mixins: [Permission],
  name: 'CuentasxcobrarView',
  components: {
    DataTableMain,
    MainFormDialog,
    ViewDetailsMain
  },
  data: () => ({
    table: {
      modelMain: [],
      showSelect: true,
      singleSelect: true,
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-cached', color: 'primary', action: 'refreshData', code: 'libre', hidden: '' },
        ],
        titles: [
          { text: 'Folio', value: 'folio', class: 'black--text' },
          { text: 'Cliente', value: 'cliente.nombres', class: 'black--text' },
          { text: '# Pagos', value: 'numero_pagos', class: 'black--text' },
          { text: 'Total', value: 'DinamicImporte', class: 'black--text', fieldMain: 'total' },
          { text: 'Interes %', value: 'caracteristicas.interes', class: 'black--text' },
          { text: 'Saldo', value: 'caracteristicas.saldo', class: 'black--text' },
          { text: '', value: 'ActionsGral', class: 'black--text', align: 'right' }
        ],
        loading: false,
      },
      body: {
        data: [],
        actions: [
          { title: 'Configurar', icon: 'mdi-pencil', color: 'amber darken-3', action: 'configure', code: 'venta.clientes.configurar' },
          { title: 'Abonar', icon: 'mdi-pencil', color: 'amber darken-3', action: 'pay', code: 'venta.clientes.abonar', funcion: 'abonar'}
        ],
      },
      footer: {
        paging: true,
      },
    },
    object: {},
    paramsDialog: {}
  }),
  mounted() {
    this.ExecuteUrl('POST/venta/cuentasxcobrar/params')
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions(['GETUrlServices', 'POSTListObjectsService','PUTDisableEnableObjectService','DELETEObjectService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'CLEAR_LIST_DATA']),
    // *==========*==========*==========*==========* \\
    successAction(item) {
      this[item.action](item);
    },
    tableAction(item) {
      this[item.action](item);
    },
    refreshData(item) {
      this.table.header.loading = true
      this.ExecuteUrl('POST/venta/cuentasxcobrar/params')
    },
    newData(item) {
      this.object = { enviar_factura: 'si' }
      this.paramsDialog = {
        form: this.get_Clientesform,
        url: this.get_urls['POST/venta/clientes'],
        message: 'Registro Agregado Correctamente',
        setmodel: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'create', with: 850, title: 'Nuevo Cliente' })
    },

    disableData  ({ item }) {
      const name = (item.activo === 'si') ? 'Desactivar' : 'Activar'
      this.$swal.fire({
        title: `Estas seguro de ${name} el registro ?`,
        // message: 'No podras revertir el movimiento',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Si, ${name}`,
        cancelButtonText: 'No, Cerrar'
      }).then((result) => {
        if (result.value) {
          const url = this.get_urls['PUT/venta/clientes/{id}']
          this.PUTDisableEnableObjectService({ url: url, params: item.id, replace: '{id}', data: {} })
        }
      })
    },
    configure ({ item }) {
      let row = item
      if (item.caracteristicas) {
        row.direccion = item.caracteristicas.direccion
        row.cp = item.caracteristicas.cp
        row.colonia = item.caracteristicas.colonia
        row.ciudad = item.caracteristicas.ciudad
        row.estado = item.caracteristicas.estado
      }
      this.object = row
      this.paramsDialog = {
        form: this.get_CuentasxcobrarConfigform,
        url: this.get_urls['POST/venta/cuentasxcobrar/configure'],
        message: 'Configurado correctamente',
        setmodel: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'create', with: '60%', title: 'Configuracion de cuentas por pagar' })
    },
    pay ({ item }) {
      let row = item
      if (item.caracteristicas) {
        row.direccion = item.caracteristicas.direccion
        row.cp = item.caracteristicas.cp
        row.colonia = item.caracteristicas.colonia
        row.ciudad = item.caracteristicas.ciudad
        row.estado = item.caracteristicas.estado
      }
      this.object = row
      this.paramsDialog = {
        form: this.get_CuentasxcobrarAbonarform,
        url: this.get_urls['POST/venta/cuentasxcobrar/pay'],
        message: 'Abonado correctamente',
        setmodel: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'create', with: '60%', title: 'Abonar a cuenta por pagar' })
    },
    updateData ({ item }) {
      let row = item
      if (item.caracteristicas) {
        row.direccion = item.caracteristicas.direccion
        row.cp = item.caracteristicas.cp
        row.colonia = item.caracteristicas.colonia
        row.ciudad = item.caracteristicas.ciudad
        row.estado = item.caracteristicas.estado
      }
      this.object = row
      this.paramsDialog = {
        form: this.get_Clientesform,
        url: this.get_urls['POST/venta/clientes'],
        message: 'Registro Agregado Correctamente',
        setmodel: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'create', with: '60%', title: 'Editar Cliente' })
    },
    deleteData  ({ item }) {
      this.$swal.fire({
        title: 'Estas seguro de  el registro ?',
        message: 'No podras revertir el movimiento',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar !',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          const url = this.get_urls['DELETE/venta/clientes/{id}']
          this.DELETEObjectService({ url: url, params: item.id, replace: '{id}' })
        }
      })
    },
    // *==========*==========*==========*==========* \\
    ExecuteUrl(url) {
      if (this.get_urls != null) {
        const router = this.get_urls[url];
        this.POSTListObjectsService({ url: router, data: {} });
      } else {
        this.GETUrlServices();
      }
    }
  },
  computed: {
    ...mapGetters(['get_urls', 'get_objects', 'get_dialogMain',
      'get_Clientesform','get_CuentasxcobrarConfigform','get_CuentasxcobrarAbonarform']),
      objectDetail() {
      if (this.table.modelMain.length > 0) {
        return this.table.modelMain[0]
      }
      return {}
    }
  },
  watch: {
    get_urls() {
      this.ExecuteUrl('POST/venta/cuentasxcobrar/params')
    },
    get_objects(data) {
      this.table.header.loading = false
      this.table.body.data = data
    }
  },
  beforeDestroy() {
    this.CLEAR_LIST_DATA();
  }
};
</script>

<style>

</style>
