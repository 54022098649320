<template>
  <v-navigation-drawer :value="toggle" app>
    <v-sheet color="grey darken-1" class="user-info pa-4" >
      <v-avatar class="mb-4" color="grey darken-1" size="64">
        <img
        src="../../assets/user.png"
        alt="John"
      >
      </v-avatar>
      <div>{{ usuario.cve_empleado }} - {{usuario.usuario}}</div>
      <div class="info-container">
        <div class="name">{{usuario.empleado}}</div>
        <div class="email">{{usuario.email}}</div>
        <div class="name"> {{usuario.sucursal}}</div>
      </div>

    </v-sheet>
    <v-divider/>

    <v-list>
      <v-list-item @click="menu('inicio')">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle class="font-weight-bold black--text">
          Inicio
        </v-list-item-subtitle>
      </v-list-item>

      <span v-for="(action, index) in menuGral" :key="index">
        <ListGroupitem v-if="action.group" :item="action" :on-success="ItemAction" />
        <ListItem v-else :item="action" :on-success="ItemAction" main="si" />
      </span>

      <v-divider />

      <v-list-item @click="menu('logout')">
        <v-list-item-action>
          <v-icon class="blanco">mdi-logout</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="blanco">Cerrar Sesion</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '@/router'
import ListItem from '@/components/fields/ListItem.vue'
import ListGroupitem from '@/components/fields/ListGroupitem.vue'
import { Permission } from '@/mixins/permissionMain'
import { UserSession } from '@/store/modules/config'
export default {
  name: 'NavigationDrawer',
  components: {
    ListItem,
    ListGroupitem
  },
  props: {
    toggle: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mixins: [Permission],
  data: () => ({
    menuGral: [
      {
        id: 'emp.dashboard',
        action: 'DashboardView',
        icon: 'mdi-chart-tree',
        title: 'Escritorio',
        hidden: 'hiddenItem'
      },
      {
        id: 'compra.menu',
        icon: 'mdi-alpha-i-box-outline',
        title: 'Compras',
        hidden: 'hiddenItem',
        group: true,
        items: [
          {
            id: 'compra.list-params',
            action: 'CompSolicitudesView',
            icon: 'mdi-cart',
            title: 'Solicitudes',
            hidden: 'hiddenItem'
          }
        ]
      },
      {
        id: 'inventario.menu',
        icon: 'mdi-border-all',
        title: 'Inventarios',
        hidden: 'hiddenItem',
        group: true,
        items: [
          {
            id: 'catalogo.productos.show-params',
            action: 'InvArticulosView',
            icon: 'mdi-view-list',
            title: 'Articulos',
            hidden: 'hiddenItem'
          },
          {
            id: 'inventario.almacenes.show-config',
            action: 'InvAlmacenView',
            icon: 'mdi-view-list',
            title: 'Almacen',
            hidden: 'hiddenItem'
          },
          {
            id: 'inventario.ajuste.list',
            action: 'InvInvetariosGralView',
            icon: 'mdi-view-list',
            title: 'Ajustes de Inventarios',
            hidden: 'hiddenItem'
          }
        ]
      },
      {
        id: 'ventas.menu',
        icon: 'mdi-storefront',
        title: 'Ventas',
        hidden: 'hiddenItem',
        group: true,
        items: [
          {
            id: 'venta.show-params',
            action: 'VentaView',
            icon: 'mdi-cart',
            title: 'Ventas',
            hidden: 'hiddenItem'
          },
          {
            id: 'ventas.mostrador.show',
            action: 'VentaMostradorView',
            icon: 'mdi-cart-plus',
            title: 'Venta Mostrador',
            hidden: 'hiddenItem'
          },
          {
            id: 'venta.caja.show',
            action: 'VentasCajaView',
            icon: 'mdi-cash-register',
            title: 'Cajas',
            hidden: 'hiddenItem'
          },
          {
            id: 'venta.caja.abierto',
            action: 'MyCajaVentasView',
            icon: 'mdi-cash-register',
            title: 'Mi Caja',
            hidden: 'hiddenItem'
          }
        ]
      },
      {
        id: 'finanzas.menu',
        icon: 'mdi-bank-outline',
        title: 'Finanzas',
        hidden: 'hiddenItem',
        group: true,
        items: [
          {
            id: 'finanzas.cuentasxcobrar.show',
            action: 'CuentasXcobrarView',
            icon: 'mdi-account-credit-card',
            title: 'Cuentas por cobrar',
            hidden: 'hiddenItem'
          },
          {
            id: 'finanzas.cuentasxpagar.show',
            action: 'CuentasXpagarView',
            icon: 'mdi-account-credit-card',
            title: 'Cuentas por pagar',
            hidden: 'hiddenItem'
          },
          {
            id: 'finanza.reports',
            icon: 'mdi-file-document',
            title: 'Reportes',
            hidden: 'hiddenItem',
            group: true,
            items: [
              {
                id: 'finanzas.reportes.show-utilidades',
                action: 'FinanzaReportsUtilidadView',
                icon: 'mdi-format-list-bulleted-square',
                title: 'Utilidades',
                hidden: 'hiddenItem'
              }
            ]
          }
        ]
      },

      {
        id: 'catalogos.menu',
        icon: 'mdi-view-list',
        title: 'Catalogos',
        hidden: 'hiddenItem',
        group: true,
        items: [
          {
            id: 'almacen.list',
            action: 'AlmacenesView',
            icon: 'mdi-format-list-bulleted',
            title: 'Almacenes',
            hidden: 'hiddenItem'
          },
          {
            id: 'sucursales.list',
            action: 'SucursalesView',
            icon: 'mdi-format-list-bulleted',
            title: 'Sucursales',
            hidden: 'hiddenItem'
          },

          {
            id: 'catalogo.caracteristicas.show',
            action: 'CaracteristicasView',
            icon: 'mdi-format-list-bulleted',
            title: 'Caracteristicas',
            hidden: 'hiddenItem'
          },
          {
            id: 'venta.clientes.show-params',
            action: 'ClientesView',
            icon: 'mdi-format-list-bulleted',
            title: 'Clientes',
            hidden: 'hiddenItem'
          },
          {
            id: 'catalogo.familias.show',
            action: 'FamiliasView',
            icon: 'mdi-format-list-bulleted',
            title: 'Familias',
            hidden: 'hiddenItem'
          },
          {
            id: 'catalogo.productos.show',
            action: 'ProductosView',
            icon: 'mdi-format-list-bulleted',
            title: 'Productos',
            hidden: 'hiddenItem'
          },
          {
            id: 'catalogo.marcas.show',
            action: 'MarcasView',
            icon: 'mdi-format-list-bulleted',
            title: 'Marcas',
            hidden: 'hiddenItem'
          },
          {
            id: 'catalogo.tipo-datos.show',
            action: 'TiposDatosView',
            icon: 'mdi-format-list-bulleted',
            title: 'Tipo de Datos',
            hidden: 'hiddenItem'
          },
          {
            id: 'catalogo.tipos-impuestos.show',
            action: 'TiposInpuestosView',
            icon: 'mdi-format-list-bulleted',
            title: 'Tipo de Impuestos',
            hidden: 'hiddenItem'
          },
          {
            id: 'catalogo.formas-pago.show',
            action: 'TiposPagosView',
            icon: 'mdi-format-list-bulleted',
            title: 'Tipo Pagos',
            hidden: 'hiddenItem'
          },
          {
            id: 'tipo-precio.list',
            action: 'TiposPreciosView',
            icon: 'mdi-format-list-bulleted',
            title: 'Tipo Precios',
            hidden: 'hiddenItem'
          },
          {
            id: 'compra.proveedores.show',
            action: 'ProveedoresView',
            icon: 'mdi-format-list-bulleted',
            title: 'Proveedores',
            hidden: 'hiddenItem'
          },
          {
            id: 'catalogo.udm.show',
            action: 'UnidadMedidasView',
            icon: 'mdi-format-list-bulleted',
            title: 'Unidad de Medidas',
            hidden: 'hiddenItem'
          }
        ]
      },

      {
        id: 'configuraciones.menu',
        icon: 'mdi-cogs',
        title: 'Configuraciones',
        hidden: 'hiddenItem',
        group: true,
        items: [
          {
            id: 'configuracion.auth',
            icon: 'mdi-book-multiple',
            title: 'Accesos',
            hidden: 'hiddenItem',
            group: true,
            items: [
              {
                id: 'accesos.usuarios.show',
                action: 'ConfigAuthUsersView',
                icon: 'mdi-account',
                title: 'Usuarios',
                hidden: 'hiddenItem'
              },
              {
                id: 'accesos.permisos.show',
                action: 'ConfigAuthPermissView',
                icon: 'mdi-account-check',
                title: 'Permisos',
                hidden: 'hiddenItem'
              },
              {
                id: 'accesos.roles.show',
                action: 'ConfigAuthRolsView',
                icon: 'mdi-account-network',
                title: 'Roles',
                hidden: 'hiddenItem'
              }
            ]
          },
          {
            id: 'configuracion.rutinas-procesos',
            icon: 'mdi-domain',
            title: 'Rutinas y Procesos',
            hidden: 'hiddenItem',
            group: true,
            items: [
              {
                id: 'configuracion.rutinas-procesos.ConteoInventario',
                action: 'ConfigRutProcConteoInventView',
                icon: 'mdi-cogs',
                title: 'Inventarios',
                hidden: 'hiddenItem'
              },
              {
                id: 'configuracion.variables-sistema.show',
                action: 'ConfigVariablesGlobalesView',
                icon: 'mdi-cogs',
                title: 'Variables Globales',
                hidden: 'hiddenItem'
              }
            ]
          },

          {
            id: 'configuracion.form-familia',
            action: 'FormCaracteristicaView',
            icon: 'mdi-collage',
            title: 'Form-Familia',
            hidden: ''
          },
          {
            id: 'empresa.empleado.show',
            action: 'ConfigEmpleadosView',
            icon: 'mdi-collage',
            title: 'Empleados',
            hidden: ''
          },
          {
            id: 'configuracion.importador-general',
            action: 'ConfigImportadorGeneralView',
            icon: 'mdi-database-import',
            title: 'Importador General',
            hidden: ''
          }
        ]
      }
    ],
    usuario: {}
  }),
  mounted () {
    this.usuario = UserSession()
    // this.PermisosMenu(JSON.parse(localStorage.getItem('permisos')), this.menuGral)
    this.PermisosMenuTwo(JSON.parse(localStorage.getItem('permisos')), this.menuGral)
    // console.log('menuGral', this.menuGral)
  },
  methods: {
    ...mapActions(['POSTLogoutService', 'GETUrlServices']),
    ItemAction (item) {
      router.push({ name: item.action })
    },
    menu (item) {
      if (item === 'logout') {
        this.$swal.fire({
          title: '¿Desea cerrar su session ?',
          text: 'Asegurese de haber concluido sus labores diarias',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Cerrar sesion',
          cancelButtonText: 'Cancelar'
        })
        .then(result => {
          if (result.value) {
            this.getUrl('GET/empresa/accesos/usuarios/logout')
          }
        })
      } else {
        router.push({ name: item })
      }
    },
    getUrl (objet) {
      if (this.get_urls != null) {
        const router = this.get_urls[objet]
        this.POSTLogoutService({ url: router, data: {} })
      } else {
        this.GETUrlServices()
      }
    }
  },
  computed: {
    ...mapGetters(['get_urls', 'get_login'])
  },
  watch: {
    get_login (val) {
      if (val.message) {
        this.$swal({
          type: 'error',
          icon: 'error',
          title: 'Error!',
          text: val.message
        })
        return
      }
      this.$cookies.remove('user_pv')
      this.$cookies.remove('token_pv')
      localStorage.clear()
      router.push('/')
    }    
  }
}
</script>

<style scoped>
/* *==========*==========*==========*==========*==========* */
/* *==========*==========*  Scroll  *==========*==========* */
.user-info {
  padding: 5px 5px 5px 5px;
  white-space: nowrap;
  position: relative;
  border-bottom: 1px solid #e9e9e9;
  /* background: url("../../assets/images/horizontal.jpg") no-repeat no-repeat; */
  min-height: 150px;
}
.user-info .image {
  margin-right: 12px;
  display: inline-block;
}
.user-info .image img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  vertical-align: bottom !important;
}
.user-info .info-container .name {
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  color: #000;
  font-weight: bold;
}
.user-info .info-container .email {
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  max-width: 200px;
  color: #000;
  font-weight: bold;
}
/* *==========*==========*==========*==========*==========* */
/* *==========*==========*==========*==========*==========* */

.hiddenItem {
  display: none !important;
}
</style>
